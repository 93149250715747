section.comment_section .login-main-box.active {
  right: 0;
  left: initial;
}

.closebtn {
  padding: 0 0 10px 0;
  text-align: right;
}

section.comment_section {
  z-index: 9999 !important;
  position: fixed !important;
}

section.comment_section .login-main-box {
  background: #f7f7f7;
  width: 450px;
  height: 100%;
  min-height: 100%;
  padding: 46px 30px;
  overflow-y: auto;
  box-shadow: 0 1px 12px 0 rgb(0 0 0 / 30%);
  position: absolute;
  right: -450px;
  top: 0;
}

section.comment_section .login-main-box.active a.closebtn {
  position: fixed;
}

section.comment_section .login-main-box a {
  text-decoration: none;
}

section.comment_section a.closebtn {
  position: absolute;
  right: 20px;
  transform: none;
  top: 20px;
  cursor: pointer;
}

.fas.fa-comment-alt {
  font-size: 20px;
  color: gray;
  padding-right: 10px;
}

section.comment_section .comment_widget1 {
  width: 100%;
  overflow: hidden;
}

section.comment_section .comment_widget1 .comment_head {
  width: 100%;
  float: left;
  margin-top: 7px;
  margin-bottom: 15px;
}

section.comment_section .comment_widget1 .comment_head span {
  display: inline-block;
  /* font-family: Merriweather,serif; */
  font-size: 18px;
  font-weight: 900;
}

section.comment_section .write_box_widget {
  width: calc(100% - 10px);
  overflow: auto;
  margin-bottom: 10px;
}

section.comment_section #comment-reply {
  margin-bottom: 5px;
}

section.comment_section #comment-reply,
.newcomment_list,
.newcomment_list ul,
.newcommentlist_wrap {
  float: left;
  width: 100%;
}

section.comment_section form,
section.comment_section li,
section.comment_section p,
section.comment_section ul {
  margin: 0;
  padding: 0;
  color: #817e7e;
}

section.comment_section .newcomment_list ul li,
section.comment_section .newcomment_list form,
section.comment_section .newcomment_list li,
section.comment_section .newcomment_list p,
section.comment_section .newcomment_list ul {
  list-style-type: none;
}

section.comment_section #subscription {
  font-size: 12px;
  color: #666;
  margin-bottom: 3px;
}

section.comment_section .comment_logindetail {
  font-size: 12px;
  margin-bottom: 10px;
  background: #f5f5f5;
  padding: 5px;
  float: left;
}

section.comment_section .comment_logindetail input {
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 12px;
  -webkit-appearance: none;
}

section.comment_section .write_box_widget textarea {
  width: 100%;
  height: 30px;
  line-height: 18px;
  margin: 0 0 10px;
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 13px;
}

section.comment_section #anonymous-login input,
.write_box_widget textarea {
  /* font-family: Roboto,Arial,Helvetica,sans-serif; */
  resize: none;
  -webkit-appearance: none;
  box-sizing: border-box;
}

section.comment_section .comment_widget1 .comment_error {
  font-size: 13px;
  font-weight: 400;
  color: red;
  margin-bottom: 10px;
}

section.comment_section .comment_widget1 .new_comment_box {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  background: 0 0;
}

section.comment_section .comment_widget1 .loginbutton {
  float: left;
  width: 100%;
  color: #069;
  text-align: center;
  margin-bottom: 10px;
}

section.comment_section .comment_widget1 .comment_login {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  display: none;
}

section.comment_section .comment_widget1 .loginbutton input {
  float: none;
  color: #fff;
  cursor: pointer;
  padding: 3px 15px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  /* font-family: Roboto,Arial,Helvetica,sans-serif; */
  text-decoration: none;
  position: relative;
  width: auto;
  height: 30px;
  background-color: #757575;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-radius: 30px;
  border-bottom: 2px solid #6d6d6d;
  transition: all 0.2s ease;
  -webkit-appearance: none;
}

section.comment_section .comment-box.active {
  left: auto;
  z-index: 10 !important;
  position: fixed !important;
  top: 0;
}

section.comment_section .comment-box {
  background: #f7f7f7;
  width: 450px;
  height: 100%;
  min-height: 100%;
  padding: 20px 30px;
  overflow-y: auto;
  box-shadow: 0 1px 12px 0 rgb(0 0 0 / 30%);
  position: absolute;
  /* right: -450px !important; */
  top: 0;
  right: 0;
}

section.comment_section .comment_widget1 .loginbutton input:hover {
  transform: scale(1.1);
}

section.comment_section .sortby .select-style select {
  padding: 5px 10px 6px;
  width: 100%;
  border: none;
  box-shadow: none;
  background: 0 0;
  -webkit-appearance: none;
  text-transform: uppercase;
  direction: rtl;
  text-align: right;
  font-size: 11px;
}

section.comment_section .sortby .select-style {
  width: 40%;
  float: right;
  overflow: hidden;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkVGM0RFMzczMTI1OTExRThBRkVFQThCQzQ1MUU5MzMzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkVGM0RFMzc0MTI1OTExRThBRkVFQThCQzQ1MUU5MzMzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUYzREUzNzExMjU5MTFFOEFGRUVBOEJDNDUxRTkzMzMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RUYzREUzNzIxMjU5MTFFOEFGRUVBOEJDNDUxRTkzMzMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7FKT03AAAAVUlEQVR42myM0QnAMAhEY/4dwp3MALqeQzmEA9heIKWUPlDO83SaWd+Mv8JuVtVw9x4f4GG3k6ra70/Q8KAJDay1mpm3xmVEEPQTACKyh8yk410CDACFL0Vd3nxk7AAAAABJRU5ErkJggg==) 100% 50% no-repeat;
  position: relative;
}

section.comment_section #anonymous-login input:focus,
.write_box_widget textarea:focus {
  border: 1px solid #fb5d49;
  outline: 0;
}

section.comment_section .write_box_widget textarea {
  width: 100%;
  height: 30px;
  line-height: 18px;
  margin: 0 0 5px;
  padding: 5px 8px;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 13px;
}

section.comment_section .comment_widget1 .new_comment_box {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  background: 0 0;
}

section.comment_section .sortby {
  width: calc(100% - 10px);
  display: block;
  float: left;
  margin: 0 0 5px;
  padding: 0 1px 0 0;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  text-align: right;
}

section.comment_section .comment_widget1 .comment_login {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  display: none;
}

/*   .comment_widget1 .comments_txt ul li {
      list-style-type: none;
    }*/

section.comment_section .comment_widget1 .comments_txt ul li>div>span {
  font-size: 12px;
}

section.comment_section .comment_widget1 .comments_txt ul li span {
  display: inline-block;
  text-transform: uppercase;
}

section.comment_section .comment_widget1 .comments_txt ul li span strong {
  font-weight: 900;
}

section.comment_section .com_user_text {
  width: 100%;
  margin: 5px 0 0;
}

section.comment_section .comment_widget1 .comments_txt {
  height: calc(100vh - 150px);
  padding-right: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

section.comment_section .comment_widget .comments_txt ul li {
  list-style-type: none;
  margin-bottom: 20px;
}

section.comment_section .comment_widget .comments_txt ul li>div>span {
  font-size: 12px;
}

section.comment_section .comment_widget .comments_txt ul li span {
  display: inline-block;
  text-transform: uppercase;
}

section.comment_section .inn-block {
  position: relative;
  padding: 20px;
  min-height: unset;
  border: 1px solid #d9d9d9;
  height: auto;
}

section.comment_section a.closebtn img {
  height: 24px;
}

section.comment_section img {
  vertical-align: middle;
  border-style: none;
}

section.comment_section button.btn.btn-primary.signbtn:hover {
  background: #fb5d49 !important;
  border-color: #fb5d49 !important;
}

section.comment_section .btn-primary {
  color: #fff;
  background-color: #fb5d49;
  border-color: #fb5d49;
}

section.comment_section a.user_name {
  color: #000;
  text-decoration: none;
  background-color: none;
}

section.comment_section .user_name {
  font-size: 12px;
  font-weight: 900;
}

section.comment_section .user_time {
  font-size: 10px;
}

section.comment_section body {
  /* font-family: 'Quicksand', 'Hind Vadodara', sans-serif; */
  font-weight: 500;
  font-size: 14px;
}

.comment_widget1 .user_comment span {
  display: inline-block;
  text-transform: uppercase;
  padding: 0 10px 0 0;
}

section.comment_section .dateline span {
  color: grey;
  font-size: 11px;
  margin: 0;
}

.com_interactives span {
  background-color: #f7f7f7;
  /* border: 1px solid #ddd; */
  margin: 5px 0 0;
  /* color: #000; */
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  line-height: 13px;
  padding: 1px;
}

.com_interactives a {
  font-size: 11px;
  color: #5b9ae4;
  font-weight: bold;
}

.user_comment {
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 5px 10px;
}

.user_comment_inner {
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 5px 15px 35px;
}

.com_interactives_inner span {
  background-color: #f7f7f7;
  /* border: 1px solid #ddd; */
  margin: 5px 0 0;
  text-align: center;
  font-weight: 400;
  line-height: 13px;
  padding: 1px;
  display: inline-flex;
  align-items: center;
}

.com_interactives_inner a {
  font-size: 11px;
  color: #5b9ae4;
  font-weight: bold;
  text-transform: uppercase;
}

.replay_new_comment_box {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
  background: 0 0;
}

.replay_new_comment_box {
  position: relative;
}

#commenttextarea {
  width: 100%;
  float: left;
  padding: 5px;
  margin: 5px 0 5px 0;
  background: 0 0;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 13px;
}

.replay_new_comment_box button {
  position: absolute;
  top: 100px;
  right: 10px;
}

#replayBtn {
  padding: 2px 8px 2px 6px;
  border-radius: 20px;
  margin: 0;
  background-color: #ae0a0b;
  color: #fff;
  font-weight: 500;
  border: 1px solid transparent;
  /* padding: 0.375rem 0.70rem; */
}

.new_comment_box {
  position: relative;
}

.new_comment_box button {
  position: absolute;
  top: 100px;
  right: 10px;
}